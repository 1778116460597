import React from 'react';
import VimTerminalResume from './components/VimTerminalResume';

function App() {
  return (
    <div className="App">
      <VimTerminalResume />
    </div>
  );
}

export default App;
